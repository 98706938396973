<template>
    <div class="bottom-nav">
        <div class="bottom-nav__sidebar bg-white">
            <img src="@/assets/icons/logout_icon.svg" alt="logout" />
            <div class="font-weight-medium" @click="doLogout">Log out</div>
        </div>
        <div class="bottom-nav__main">
            <div class="navbar">
                <nav class="navbar__left">
                    <router-link v-slot="{ isActive, navigate }" to="/dashboard">
                        <div class="navbar__icon-container" @click="navigate">
                            <img v-show="!isActive" src="@/assets/icons/home_icon.svg" />
                            <img v-show="isActive" src="@/assets/icons/home_active_icon.svg" />
                            <div class="smaller">home</div>
                        </div>
                    </router-link>
                    <router-link v-slot="{ isActive, navigate }" to="/events">
                        <div class="navbar__icon-container" @click="navigate">
                            <img v-show="!isActive" src="@/assets/icons/events_icon.svg" />
                            <img v-show="isActive" src="@/assets/icons/events_active_icon.svg" />
                            <div class="smaller">events</div>
                        </div>
                    </router-link>
                </nav>
                <div class="navbar__middle">
                    <div class="middle__left"></div>
                    <div class="middle__center"></div>
                    <div class="middle__right"></div>
                    <div class="navbar__center-button">
                        <router-link v-slot="{ navigate }" to="/create-event">
                            <img
                                id="createEvent"
                                src="@/assets/create_event_button.svg"
                                @click="navigate"
                            />
                        </router-link>
                    </div>
                </div>
                <nav class="navbar__right">
                    <router-link v-slot="{ isActive, navigate }" to="/inbox">
                        <div class="navbar__icon-container" @click="navigate">
                            <img v-show="!isActive" src="@/assets/icons/messages_icon.svg" />
                            <img v-show="isActive" src="@/assets/icons/messages_active_icon.svg" />
                            <div class="smaller">message</div>
                        </div>
                    </router-link>
                    <router-link v-slot="{ isActive, navigate }" to="/profile/me">
                        <div class="navbar__icon-container" @click="navigate">
                            <img v-show="!isActive" src="@/assets/icons/profile_icon.svg" />
                            <img v-show="isActive" src="@/assets/icons/profile_active_icon.svg" />
                            <div class="smaller">profile</div>
                        </div>
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BottomNav',
    inject: ['logout'],
    methods: {
        doLogout() {
            this.logout()
            this.$router.replace('/')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

// .sidebar-left-open {
//     transform: translateX(0vw) !important;
// }

// .sidebar-right-open {
//     transform: translateX(-140vw) !important;
// }

.bottom-nav {
    position: fixed;
    height: 90px;
    left: 0;
    bottom: 0;
    width: 170vw;
    display: flex;
    transition: transform $sidebarAnimationDuration ease;
    // transform: translateX(-70vw);

    &__sidebar {
        flex: 0 1 70vw;
        height: 100%;
        display: grid;
        grid-template-columns: auto auto 1fr;
        place-items: center;
        column-gap: 1rem;
        padding: 1rem;
        align-content: start;
        border-top: 1px solid $light;
    }

    &__main {
        flex: 0 1 100vw;
        height: 100% !important;
    }

    .navbar {
        height: 100%;
        position: relative;

        display: grid;
        grid-template-columns: 1fr auto 1fr;

        &__right,
        &__left {
            height: 100%;
            background: white;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        &__icon-container {
            display: grid;
            justify-items: center;
            align-content: center;
            row-gap: 0.5rem;
            padding: 0.5rem;
            cursor: pointer;
        }

        &__center-button {
            $button-size: 36px;
            position: absolute;
            display: grid;
            place-items: center;
            top: 7px;
            left: calc(50% - (36px / 2));
            cursor: pointer;

            img {
                width: $button-size;
                height: $button-size;
            }
        }

        &__middle {
            height: 100%;
            > *:not(.navbar__center-button) {
                margin-left: -1px;
                margin-right: -1px;
                background: rgba(255, 0, 0, 0.315);
            }
            .middle {
                &__right {
                    background: white;
                    height: 100%;
                    border-top-left-radius: 1.5em;
                    display: inline-block;
                    width: 30px;
                }
                &__left {
                    background: white;
                    height: 100%;
                    width: 30px;
                    display: inline-block;
                    border-top-right-radius: 1.5em;
                }
                &__center {
                    height: 70%;
                    background: white;
                    width: 55px;
                    align-self: end;
                    display: inline-block;
                    mask-image: radial-gradient(
                        circle at top center,
                        transparent 0,
                        transparent 36%,
                        white 38%,
                        white 100%
                    );
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .bottom-nav {
        display: none;
    }
}
</style>
