<template>
    <div class="sidebar text-primary">
        <div v-if="$mq === 'sm'" class="separator bg-light"></div>

        <div class="sidebar__body">
            <div v-if="me" class="sidebar-box">
                <p class="sidebar-box__header">Your accounts</p>

                <SwitchProfileButton
                    :profile="myFan"
                    :notification-count="myNotificationSummary().fan"
                    @click="handleProfileSwitch('fan')"
                />
                <Media v-if="!myArtist" vertical-align="center">
                    <template #aside>
                        <div class="avatar-xsm" :style="{ display: 'grid', placeItems: 'center' }">
                            <img class="icon-sm" src="@/assets/plus.svg" alt="plus" />
                        </div>
                    </template>
                    <router-link to="/register-artist"> Create artist page </router-link>
                </Media>
                <SwitchProfileButton
                    v-else-if="myArtist"
                    :notification-count="myNotificationSummary().artist"
                    :profile="myArtist"
                    @click="handleProfileSwitch('artist')"
                />
                <Media v-if="!myVenue" vertical-align="center">
                    <template #aside>
                        <div class="avatar-xsm" :style="{ display: 'grid', placeItems: 'center' }">
                            <img class="icon-sm" src="@/assets/plus.svg" alt="plus" />
                        </div>
                    </template>
                    <router-link to="/register-venue"> Create venue page </router-link>
                </Media>
                <SwitchProfileButton
                    v-else-if="myVenue"
                    :notification-count="myNotificationSummary().venue"
                    :profile="myVenue"
                    @click="handleProfileSwitch('venue')"
                />
            </div>

            <div v-if="$mq !== 'sm'" class="separator bg-light"></div>
            <div v-if="$mq !== 'sm'" class="sidebar-box">
                <Media vertical-align="center">
                    <template #aside>
                        <div
                            :style="{
                                display: 'grid',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'start'
                            }"
                        >
                            <img src="@/assets/create_event_button.svg" />
                        </div>
                    </template>
                    <div
                        :style="{
                            display: 'grid',
                            alignItems: 'center',
                            height: '100%',
                            justifyContent: 'start'
                        }"
                    >
                        <router-link v-slot="{ navigate }" to="/create-event">
                            <button class="create-event-button" @click="navigate">
                                Create event
                            </button>
                        </router-link>
                    </div>
                </Media>
            </div>
            <div v-if="$mq !== 'sm'" class="separator bg-light"></div>
            <nav class="sidebar__nav">
                <router-link
                    v-slot="{ isActive, navigate }"
                    to="/dashboard"
                    class="sidebar__nav-item"
                >
                    <Media vertical-align="center" @click="navigate">
                        <template #aside>
                            <img
                                v-show="!isActive"
                                class="icon-md"
                                src="@/assets/icons/home_icon.svg"
                                alt="home"
                            />
                            <img
                                v-show="isActive"
                                class="icon-md"
                                src="@/assets/icons/home_active_icon.svg"
                                alt="home"
                            />
                        </template>
                        <p>Home</p>
                    </Media>
                </router-link>
                <router-link v-slot="{ isActive, navigate }" to="/events" class="sidebar__nav-item">
                    <Media vertical-align="center" @click="navigate">
                        <template #aside>
                            <img
                                v-show="!isActive"
                                class="icon-md"
                                src="@/assets/icons/events_icon.svg"
                                alt="home"
                            />
                            <img
                                v-show="isActive"
                                class="icon-md"
                                src="@/assets/icons/events_active_icon.svg"
                                alt="home"
                            />
                        </template>
                        <p>Events</p>
                    </Media>
                </router-link>
                <router-link v-slot="{ isActive, navigate }" to="/inbox" class="sidebar__nav-item">
                    <Media vertical-align="center" @click="navigate">
                        <template #aside>
                            <img
                                v-show="!isActive"
                                class="icon-md"
                                src="@/assets/icons/messages_icon.svg"
                                alt="home"
                            />
                            <img
                                v-show="isActive"
                                class="icon-md"
                                src="@/assets/icons/messages_active_icon.svg"
                                alt="home"
                            />
                        </template>
                        <p>Message</p>
                    </Media>
                </router-link>
                <router-link
                    v-slot="{ isActive, navigate }"
                    to="/profile/me"
                    class="sidebar__nav-item"
                >
                    <Media vertical-align="center" @click="navigate">
                        <template #aside>
                            <img
                                v-show="!isActive"
                                class="icon-md"
                                src="@/assets/icons/profile_icon.svg"
                                alt="home"
                            />
                            <img
                                v-show="isActive"
                                class="icon-md"
                                src="@/assets/icons/profile_active_icon.svg"
                                alt="home"
                            />
                        </template>
                        <p>Profile</p>
                    </Media>
                </router-link>
            </nav>
            <div class="separator bg-light"></div>

            <div class="grid sidebar-box">
                <!-- <Media>
                    <template #aside>
                        <img class="icon-md" src="@/assets/icons/legal_finance_icon.svg" />
                    </template>
                    <template><p>Legal and Finance</p></template>
                </Media>
                <Media>
                    <template #aside>
                        <img class="icon-md" src="@/assets/icons/user_details_icon.svg" />
                    </template>
                    <template><p>User details</p></template>
                </Media>
                <Media>
                    <template #aside>
                        <img class="icon-md" src="@/assets/icons/settings_small_icon.svg" />
                    </template>
                    <template><p>Settings</p></template>
                </Media>
                <Media>
                    <template #aside>
                        <img class="icon-md sidebar-box__image" src="@/assets/info.svg" />
                    </template>
                    <template><p>Information</p></template>
                </Media> -->
            </div>
        </div>
        <div class="separator bg-light"></div>
        <div class="sidebar__footer">
            <Media v-if="$mq !== 'sm'" vertical-align="center">
                <template #aside>
                    <img src="@/assets/icons/logout_icon.svg" alt="logout" />
                </template>
                <div class="font-weight-medium" @click="doLogout">Log out</div>
            </Media>
        </div>
    </div>
</template>

<script>
import Media from '@/components/Media'
import SwitchProfileButton from '@/components/sidebar/SwitchProfileButton'
import { ME } from '@/graphql/queries/user'
export default {
    name: 'Sidebar',
    components: {
        Media,
        SwitchProfileButton
    },
    apollo: {
        me: {
            query: ME
        }
    },
    inject: [
        'logout',
        'isProfileLoading',
        'getMyCurrentProfile',
        'switchToFanProfile',
        'switchToArtistProfile',
        'switchToVenueProfile',
        'myNotificationSummary'
    ],
    props: {
        toggleSidebar: {
            required: true,
            type: Function
        }
    },
    computed: {
        myFan() {
            return this.me?.fan
        },
        myArtist() {
            return this.me?.artists?.[0]
        },
        myVenue() {
            return this.me?.venues?.[0]
        }
    },
    methods: {
        doLogout() {
            this.logout()
            this.$router.replace('/')
        },
        handleProfileSwitch(profileType) {
            const funcs = {
                venue: this.switchToVenueProfile,
                artist: this.switchToArtistProfile,
                fan: this.switchToFanProfile
            }

            const switchProfileFunc = funcs[profileType]
            switchProfileFunc()
            this.toggleSidebar()
            if (this.$route.name !== 'Dashboard') {
                this.$router.replace('/dashboard')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.create-event-button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: 0;
    line-height: 0.9rem;
    color: $beige;
    background-color: $primary;
    border: 1px solid $primary;
    font-size: 14px;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 0 50%);
}

.grid {
    display: grid;
    column-gap: 1rem;
}

.sidebar {
    height: 100%;
    // width: 100vw;
    display: flex;
    flex-direction: column;
    /* Make the last sidebar-box fill remaining height */
    > :nth-last-child(3) {
        flex: 1;
    }
    &__footer {
        padding: 1rem;
    }
    &__nav {
        display: none;
    }
}

.sidebar__nav-item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;
    align-items: center;
}

.sidebar-wrapper.sidebar-open .sidebar__header {
    transform: translateX(0vw);
}

.sidebar__header {
    // transform: translatex(-30vw);
    // position: fixed;
    transition: transform $sidebarAnimationDuration ease;
    width: 100vw;
    height: 90px;
    z-index: 2;
    background: #fff;
    padding: 1rem 1rem 0.5rem;
    grid-template-columns: auto 1fr auto;
    column-gap: 1rem;
    align-content: end;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .sidebar__header {
        display: none;
    }

    .sidebar__nav {
        display: grid;
        row-gap: 1rem;
        padding: 1rem;
    }
}

.sidebar__body {
    border-right: 1px solid $light;
}

.sidebar-box {
    // grid-template-columns: auto 1fr;
    // padding: 1rem;
    // row-gap: 1rem;
    // column-gap: 1rem;
    // align-items: center;
    display: grid;
    padding: 1rem;
    row-gap: 1rem;
    grid-auto-columns: 1fr;
}

.sidebar-box__image {
    justify-self: center;
}

.sidebar__footer {
    height: 90px;
}

.separator {
    height: 1px;
    width: 100%;
}
</style>
