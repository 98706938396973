<template>
    <router-link v-slot="{ navigate }" :to="{ path: getContextUrl(notification) }" replace>
        <Media
            vertical-align="center"
            gap-size=".5rem"
            :style="{ cursor: 'pointer ' }"
            @click="handleClick({ navigate: navigate, event: $event })"
        >
            <template #aside>
                <img :src="notification.sender.avatar" class="avatar-sm" />
            </template>
            <div class="notification" :class="{ 'notification--read': notification.isRead }">
                <div class="notification__body">
                    <span class="notification__sender"> {{ notification.sender.name }}</span>
                    {{ notificationMessage }}
                </div>
                <span
                    class="notification__received-at smaller"
                    :class="{ 'font-weight-bold': !notification.isRead }"
                >
                    {{ receivedAt }}
                </span>
            </div>
        </Media>
    </router-link>
</template>

<script>
import Media from '@/components/Media.vue'
import { toTimeSinceStringShort } from '@/utils/dateUtils.js'
export default {
    name: 'NotificationItem',
    components: {
        Media
    },
    inject: ['readNotification'],
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    computed: {
        receivedAt() {
            return toTimeSinceStringShort(new Date(this.notification.updatedAt))
        },
        notificationMessage() {
            if (this.notification.context.ref.message === 'status updated') {
                return 'just updated the request status!'
            } else {
                return `sent you a message: ${this.notification.context.ref.message}`
            }
        }
    },
    methods: {
        getContextUrl(notification) {
            if (!notification.context) {
                throw new Error('Missing context')
            }
            switch (notification.context.type) {
                case 'conversation':
                    return `/conversation/${notification.context.id}`
                case 'event_request':
                    return `/request/${notification.context.id}`
                case 'event_request_update':
                    return `/request/${notification.context.id}`
                default:
                    throw new Error('Unknown context type', notification.context.type)
            }
        },
        async handleClick({ navigate, event }) {
            if (!this.notification.isRead) {
                const { mutate } = this.readNotification()
                mutate(this.notification.id)
            }
            navigate(event)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.notification {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 0.5rem;
    &__body {
        align-self: center;
        font-size: 14px;
        max-height: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__sender {
        font-weight: 900;
    }
    &__received_at {
        font-weight: 900;
    }
    &--read {
        opacity: 0.5;
        .notification__sender {
            font-weight: normal;
        }
        .notification__received_at {
            font-weight: normal;
        }
    }
}
</style>
