var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-nav"},[_c('div',{staticClass:"bottom-nav__sidebar bg-white"},[_c('img',{attrs:{"src":require("@/assets/icons/logout_icon.svg"),"alt":"logout"}}),_c('div',{staticClass:"font-weight-medium",on:{"click":_vm.doLogout}},[_vm._v("Log out")])]),_c('div',{staticClass:"bottom-nav__main"},[_c('div',{staticClass:"navbar"},[_c('nav',{staticClass:"navbar__left"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{staticClass:"navbar__icon-container",on:{"click":navigate}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!isActive),expression:"!isActive"}],attrs:{"src":require("@/assets/icons/home_icon.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],attrs:{"src":require("@/assets/icons/home_active_icon.svg")}}),_c('div',{staticClass:"smaller"},[_vm._v("home")])])]}}])}),_c('router-link',{attrs:{"to":"/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{staticClass:"navbar__icon-container",on:{"click":navigate}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!isActive),expression:"!isActive"}],attrs:{"src":require("@/assets/icons/events_icon.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],attrs:{"src":require("@/assets/icons/events_active_icon.svg")}}),_c('div',{staticClass:"smaller"},[_vm._v("events")])])]}}])})],1),_c('div',{staticClass:"navbar__middle"},[_c('div',{staticClass:"middle__left"}),_c('div',{staticClass:"middle__center"}),_c('div',{staticClass:"middle__right"}),_c('div',{staticClass:"navbar__center-button"},[_c('router-link',{attrs:{"to":"/create-event"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('img',{attrs:{"id":"createEvent","src":require("@/assets/create_event_button.svg")},on:{"click":navigate}})]}}])})],1)]),_c('nav',{staticClass:"navbar__right"},[_c('router-link',{attrs:{"to":"/inbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{staticClass:"navbar__icon-container",on:{"click":navigate}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!isActive),expression:"!isActive"}],attrs:{"src":require("@/assets/icons/messages_icon.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],attrs:{"src":require("@/assets/icons/messages_active_icon.svg")}}),_c('div',{staticClass:"smaller"},[_vm._v("message")])])]}}])}),_c('router-link',{attrs:{"to":"/profile/me"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('div',{staticClass:"navbar__icon-container",on:{"click":navigate}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!isActive),expression:"!isActive"}],attrs:{"src":require("@/assets/icons/profile_icon.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(isActive),expression:"isActive"}],attrs:{"src":require("@/assets/icons/profile_active_icon.svg")}}),_c('div',{staticClass:"smaller"},[_vm._v("profile")])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }