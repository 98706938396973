<template>
    <div class="notification-sidebar">
        <div class="separator bg-light" />
        <div class="notifications">
            <h4>Notifications</h4>
            <LoadingEllipsis v-if="myNotificationsQuery().loading">
                <small>Loading Notifications</small>
            </LoadingEllipsis>
            <small v-else-if="!myNotificationsQuery().data.length">
                Notifications empty
            </small>
            <NotificationItem
                v-for="notification in myNotificationsQuery().data"
                :key="notification.id"
                :notification="notification"
            />
            <div
                v-if="myNotificationsQuery().data.length"
                :style="{ display: 'grid', placeItems: 'center' }"
            >
                <Button
                    label="Mark all as read"
                    size="small"
                    square
                    :disabled="isAllRead"
                    :class="{ disabled: isAllRead }"
                    @click="readAllNotifications().mutate()"
                />
            </div>
        </div>
        <div class="notification-sidebar__footer">
            <div class="separator bg-light" />
        </div>
    </div>
</template>

<script>
import NotificationItem from '@/components/notifications/NotificationItem'

export default {
    name: 'NotificationSidebar',
    components: {
        NotificationItem
    },
    inject: ['getMyCurrentProfile', 'myNotificationsQuery', 'readAllNotifications'],
    computed: {
        isAllRead() {
            return !this.myNotificationsQuery().data.some((notification) => !notification.isRead)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.disabled {
    opacity: 0.5;
}
.notification-sidebar {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr 90px;
    background: white;
    width: 70vw;
    border-left: 1px solid $light;
    // height: 100%;
    .notifications {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
        padding: 1rem;
        & > *:not(:first-child) {
            padding-top: 1rem;
        }

        .notification {
            display: grid;
            grid-template-columns: auto 1fr auto;
            column-gap: 0.5rem;
        }
    }
    .separator {
        height: 1px;
        width: 100%;
    }
}
@media only screen and (min-width: 768px) {
    .notification-sidebar {
        width: 100%;
    }
}
</style>
