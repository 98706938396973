<template>
    <ImageWithBurger
        v-if="getMyCurrentProfile()"
        :src="getMyCurrentProfile().avatar"
        v-bind="$attrs"
        @click="$emit('click', $event)"
    />
</template>

<script>
import ImageWithBurger from '@/components/ImageWithBurger'
export default {
    name: 'CurrentProfileBurger',
    components: { ImageWithBurger },
    inject: ['isProfileLoading', 'getMyCurrentProfile']
}
</script>
