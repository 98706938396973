<template>
    <div class="image-burger-container" @click="$emit('click')">
        <svg width="100%" height="100%">
            <mask id="m" fill="#fff">
                <rect id="r" width="100%" height="100%" rx="50%" />
                <rect x="-15%" y="-15%" width="60%" height="60%" ry="5%" fill="#000" />
            </mask>
            <image
                :href="src"
                height="100%"
                width="100%"
                mask="url(#m)"
                preserveAspectRatio="xMinYMin slice"
            ></image>
        </svg>
        <div class="burger" :class="{ burger__dark: dark }">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div v-if="hasNotifications" class="notification-notch"></div>
    </div>
</template>

<script>
export default {
    name: 'ImageWithBurger',
    inject: ['myNotificationSummary'],
    props: {
        src: {
            type: String,
            required: true
        },
        dark: {
            type: Boolean
        }
    },
    emits: ['click'],
    computed: {
        hasNotifications() {
            const { artist, venue, fan } = this.myNotificationSummary()
            return artist || venue || fan
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
.notification-notch {
    width: 10px;
    height: 10px;
    color: tomato;
    background-color: red;
    border-radius: 50%;
    border: 1px solid white;
    position: absolute;
    top: 0;
    right: 0;
}
.image-burger-container {
    width: 32px;
    height: 32px;
    position: relative;
    cursor: pointer;
    svg {
        position: absolute;
    }
    .burger {
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        height: 40%;

        display: grid;
        grid-template-rows: repeat(5, 1fr);

        div {
            width: 100%;
            &:nth-child(odd) {
                background: white;
                // width: 50%;
                height: 2px;
            }
            &:last-child {
                width: 50%;
            }
        }

        &.burger__dark {
            div {
                &:nth-child(odd) {
                    background: $primary;
                }
            }
        }
    }
}
</style>
