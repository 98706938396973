<template>
    <div class="icon-with-notification" @click="$emit('click', $event)">
        <div class="icon-with-notification__icon-wrapper">
            <slot name="icon" />
        </div>
        <div
            v-if="$slots['notch-content'] && $slots['notch-content'].length"
            class="icon-with-notification__notch"
        >
            <slot name="notch-content" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'IconWithNotification',
    emits: ['click']
}
</script>

<style lang="scss" scoped>
.icon-with-notification {
    position: relative;
    &__icon-wrapper {
        display: grid;
    }
    &__notch {
        position: absolute;
        top: -10px;
        right: -10px;
        background: red;
        color: white;
        border-radius: 50%;
        border: 2px solid white;
        box-sizing: content-box;
        font-size: 12px;
        line-height: 16px;
        width: 18px;
        height: 18px;
        display: grid;
        place-items: center;
    }
}
</style>
