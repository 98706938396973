<template>
    <div class="wrapper text-primary">
        <header class="desktop-header">
            <img
                src="@/assets/logo_primary.svg"
                alt="logo"
                class="avatar-md"
                :style="{ justifySelf: 'start' }"
            />
            <Media vertical-align="center">
                <template #aside>
                    <img :src="getMyCurrentProfile().avatar" alt="avatar" class="avatar-sm" />
                </template>
                <div
                    :style="{
                        width: '100%',
                        height: '100%',
                        display: 'grid',
                        placeItems: 'center'
                    }"
                >
                    {{ getMyCurrentProfile().displayName }}
                </div>
            </Media>

            <Button
                v-if="$mq === 'md' && isMdSidebarRightOpen"
                label="Close panel"
                size="small"
                square
                @click="isMdSidebarRightOpen = !isMdSidebarRightOpen"
            />
            <Button
                v-else-if="$mq === 'md'"
                label="Open panel"
                size="small"
                square
                @click="isMdSidebarRightOpen = !isMdSidebarRightOpen"
            />
        </header>
        <div class="layout" :class="sidebarClasses">
            <aside class="sidebar-left" :class="sidebarOpenClasses">
                <header class="sidebar-left-header">
                    <!-- <slot name="sidebar-left-header" :toggle-sidebar="toggleSidebarLeft" /> -->

                    <img :src="getMyCurrentProfile().avatar" class="avatar-sm" />
                    <h3 class="font-weight-medium">
                        {{ getMyCurrentProfile().displayName }}
                    </h3>
                    <button class="button button-close" @click="toggleSidebarLeft"></button>

                    <!-- <template #sidebar-left-header="{ toggleSidebar}">
                        
                    </template>
                    <template #sidebar-right-header="{ toggleSidebar }">
                        <div class="sidebar-header__left">
                            <img :src="getMyCurrentProfile().avatar" class="avatar-sm" />
                            <h3 class="font-weight-medium">
                                {{ getMyCurrentProfile().displayName }}
                            </h3>
                            <button class="button button-close" @click="toggleSidebar"></button>
                        </div>
                    </template> -->
                </header>
                <Sidebar :toggle-sidebar="toggleSidebarLeft" />
            </aside>
            <main class="main" :class="sidebarOpenClasses">
                <slot
                    name="main"
                    :toggle-sidebar-left="toggleSidebarLeft"
                    :toggle-sidebar-right="toggleSidebarRight"
                />
                <!-- Overlay on main content to toggle sidebar -->
                <div
                    v-if="sidebarState !== 0 && $mq === 'sm'"
                    class="content-overlay"
                    @click.self="resetSidebars"
                />
            </main>
            <transition name="test">
                <aside
                    v-show="$mq !== 'md' || isMdSidebarRightOpen"
                    class="sidebar-right"
                    :class="sidebarOpenClasses"
                >
                    <header class="sidebar-right-header">
                        <!-- <slot name="sidebar-right-header" :toggle-sidebar="toggleSidebarRight" /> -->
                        <img :src="getMyCurrentProfile().avatar" class="avatar-sm" />
                        <h3 class="font-weight-medium">
                            {{ getMyCurrentProfile().displayName }}
                        </h3>
                        <button class="button button-close" @click="toggleSidebarRight"></button>
                    </header>
                    <NotificationSidebar />
                </aside>
            </transition>
            <BottomNav />
        </div>
    </div>
</template>

<script>
import BottomNav from '@/components/BottomNav'
import Sidebar from '@/components/layout/Sidebar'
import Media from '@/components/Media'
import NotificationSidebar from '@/components/layout/NotificationSidebar'
export default {
    name: 'WithSidebar',
    components: {
        BottomNav,
        Sidebar,
        NotificationSidebar,
        Media
    },
    inject: ['getMyCurrentProfile'],
    data() {
        return {
            sidebarLeft: false,
            sidebarRight: false,
            sidebarState: 0,
            isMdSidebarRightOpen: false
        }
    },
    computed: {
        sidebarClasses() {
            if (this.$mq !== 'sm') {
                return 'layout--open-none'
            }
            if (this.sidebarState === -1) {
                return 'layout--open-left'
            } else if (this.sidebarState === 0) {
                return 'layout--open-none'
            } else {
                return 'layout--open-right'
            }
        },
        sidebarOpenClasses() {
            return {
                'sidebar-open': this.sidebarState !== 0
            }
        }
    },
    methods: {
        toggleSidebarLeft() {
            if (this.sidebarState === -1) {
                this.sidebarState = 0
            } else {
                this.sidebarState = -1
            }
        },
        toggleSidebarRight() {
            if (this.sidebarState === 1) {
                this.sidebarState = 0
            } else {
                this.sidebarState = 1
            }
        },
        resetSidebars() {
            this.sidebarState = 0
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
$headerHeight: 90px;
$desktopHeaderHeight: 75px;

.test-enter,
.test-leave-to {
    transform: translateX(300px);
}

.test-enter-to,
.test-leave {
    transform: translateX(0);
}

.test-enter-active,
.test-leave-active {
    transition: transform 0.3s ease;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -10;
    transition: transform $sidebarAnimationDuration ease;
    .layout {
        display: grid;
        grid-template-columns: 70vw 100vw 70vw;
        height: 100vh;
        width: 240vw;
        transition: transform 0.3s ease;
        &--open-left {
            transform: translateX(0);
        }
        &--open-none {
            transform: translateX(-70vw);
        }
        &--open-right {
            transform: translateX(-140vw);
        }
    }
    .desktop-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $desktopHeaderHeight;
        z-index: 30;
        background: white;
        display: none;
        grid-template-columns: 1fr auto 1fr;
        justify-items: center;
        align-items: center;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid $light;
        & > * {
            justify-self: end;
        }
    }

    .sidebar-left {
        width: 70vw;
        height: 100vh;
        transition: transform $sidebarAnimationDuration ease;

        &-header {
            transition: transform $sidebarAnimationDuration ease;
            width: 100vw;
            height: $headerHeight;
            z-index: 20;

            background: white;
            display: grid;
            padding: 1rem 1rem 0.5rem;
            grid-template-columns: auto 1fr auto;
            column-gap: 1rem;
            align-content: end;
            align-items: center;
        }

        &.sidebar-open {
            .sidebar-left-header {
                transform: translateX(0);
            }
        }
    }

    .main {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: relative;
        transition: transform $sidebarAnimationDuration ease;

        &.sidebar-open {
            z-index: -1;
        }
    }

    .sidebar-right {
        width: 70vw;
        height: 100vh;
        display: grid;
        grid-template-rows: $headerHeight 1fr;
        transition: transform $sidebarAnimationDuration ease;

        &-header {
            width: 100vw;
            height: $headerHeight;
            transition: transform $sidebarAnimationDuration ease;
            z-index: 20;
            background: white;
            display: grid;
            padding: 1rem 1rem 0.5rem;
            grid-template-columns: auto 1fr auto;
            column-gap: 1rem;
            align-content: end;
            align-items: center;
        }
        &.sidebar-open {
            .sidebar-right-header {
                transform: translateX(-30vw);
            }
        }
    }
}

.content-overlay {
    background: transparent;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    // background: tomato;
    // opacity: 0.5;
    &.sidebar-left-open {
        left: 70vw;
        top: $headerHeight;
        bottom: 0;
    }
    &.sidebar-right-open {
        left: 0vw;
        width: 30vw;
        top: $headerHeight;
    }
}

@media only screen and (min-width: 768px) {
    .wrapper {
        .desktop-header {
            display: grid;
        }
        .layout {
            width: 100vw;
            transform: translateX(0);

            .sidebar-left {
                padding-top: $desktopHeaderHeight;

                width: 300px;
                position: fixed;
                z-index: 2;
                // transform: translateX(0);
                &-header {
                    display: none;
                }
            }
            .main {
                padding-top: $desktopHeaderHeight;

                width: 100vw;
                overflow: auto;
                // padding-right: 300px;
                padding-left: 300px;
                // transform: translateX(0);
            }
            .sidebar-right {
                padding-top: $desktopHeaderHeight;

                width: 300px;
                position: fixed;
                right: 0;
                // transform: translateX(0);
                display: grid;
                grid-template-rows: 1fr;
                &-header {
                    display: none;
                }
            }
        }
    }
}
@media only screen and (min-width: 1025px) {
    .wrapper {
        .layout {
            .main {
                padding-right: 300px;
            }
        }
    }
}
</style>
