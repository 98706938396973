<template>
    <div class="gb-media" @click="$emit('click', $event)">
        <div class="gb-media__grid" :style="{ columnGap: gapSize }">
            <aside class="gb-media__aside" :class="classes">
                <slot name="aside"></slot>
            </aside>
            <div class="gb-media__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Media',
    props: {
        verticalAlign: {
            type: String,
            validator(val) {
                return ['top', 'start', 'center', 'end', 'bottom'].includes(val)
            },
            default: 'start'
        },
        gapSize: {
            type: String,
            default: '1rem'
        }
    },
    computed: {
        classes() {
            return {
                [`gb-media__aside--align-${this.verticalAlign}`]: true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.gb-media {
    display: block;
    &__grid {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    &__aside {
        display: grid;
    }
    &__aside--align-top,
    &__aside--align-start {
        align-self: start;
        justify-items: start;
    }

    &__aside--align-center {
        align-self: center;
        justify-items: center;
    }

    &__aside--align-end,
    &__aside--align-bottom {
        align-self: end;
        justify-items: center;
    }
}
</style>
