<template>
    <Media vertical-align="center" @click="$emit('click', $event)">
        <template #aside>
            <img v-if="!notificationCount" class="avatar-xsm" :src="profile.avatar" />
            <IconWithNotification v-else>
                <template #icon>
                    <img class="avatar-xsm" :src="profile.avatar" />
                </template>
                <template #notch-content>
                    {{ notificationCount }}
                </template>
            </IconWithNotification>
        </template>
        <template #default>
            <div class="font-weight-medium">{{ profile.displayName }}</div>
            <p>
                <span class="smaller">{{ bottomText }}</span>
            </p>
        </template>
    </Media>
</template>

<script>
import Media from '@/components/Media'
import IconWithNotification from '@/components/IconWithNotification'
export default {
    name: 'SwitchProfileButton',
    components: {
        Media,
        IconWithNotification
    },
    props: {
        profile: {
            type: Object,
            validator({ avatar, displayName }) {
                return avatar && displayName
            },
            required: true
        },
        notificationCount: {
            type: Number,
            required: true
        }
    },
    emits: ['click'],
    computed: {
        bottomText() {
            const { __typename } = this.profile
            return __typename === 'Fan' ? 'Personal Page' : `${__typename} Page`
        }
    }
}
</script>

<style lang="scss">
.gb-media {
    cursor: pointer;
}
</style>
